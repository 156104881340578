<style lang="scss">

  .heading-index {
    font-family: $primary-font;
    margin: 30px 0 25px;

    .content {
      color: $color-primary;
      font-size: 54px;
      line-height: 60px;
      font-weight: 500;
      letter-spacing: 2px;
        span.bold {
          display: block;
          font-weight: bold;
      }
    }

    .content-full {
      color: $color-secondary;
      text-transform: uppercase;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      &::after,
      &::before {
        content: '';
        width: 55px;
        height: 1px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 35px;
        background-color: $color-secondary;
      }
    }
  }

</style>

<template lang="pug">

  .heading-index
    span(:class="line")
      slot

</template>

<script>

export default {
  name: 'Heading',

  props: {
    side: { type: String, default: 'left' }
  },

  computed: {
    line() {
      if (this.side === 'full') return 'content-full';
      return 'content';
    }
  }
};

</script>
