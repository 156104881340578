import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
// LP
import LandingPageRoute from './landing-page/index';
import UnitRoute from './unit';
import Edition from './editions';
import Login from './login';

Vue.use(VueRouter);

const routes = [...LandingPageRoute, ...UnitRoute, ...Edition, ...Login];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return {
      x: 0,
      y: 0
    };
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log(store.getters.user);
    if (store.getters.user.id) {
      next();
    } else {
      next('/login');
    }
  }
  next();
});

export default router;
