const UnitRoute = [
  {
    path: '/unit/:id',
    name: 'Unit',
    component: () => import('../../views/unit/index.vue'),
    meta: { title: 'LW - UNIDADES', requiresAuth: true }
  }
];

export default UnitRoute;
