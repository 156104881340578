<style lang="scss">

  .view-index {
    // background-image: linear-gradient(180deg, transparent 75%, black), linear-gradient(to right bottom, #0f0f0f, #1d1e24, #282d39, #303e4f, #345066);
    background-image: linear-gradient(180deg, transparent 75%, #000), linear-gradient(to right bottom, #0f0f0f, #363740, #4a4f5a, #3d4c5e, #506a7e);
    background-size: 100% 100vh;
    background-repeat: no-repeat;

    @include tablet {
      // background-size: 100% 590px;
    }

    .content {
      width: 100%;
      margin: 0 auto;
      overflow-x: hidden;

      .slide-enter-active {
        animation: slide .3s ease-out;
      }
      .slide-leave-active {
        animation: slide .3s ease-out reverse;
      }

      @keyframes slide {
        0% {
          opacity: 0;
          transform: translateX(60px);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }

      .view {
        // min-height: calc(100vh - 100px - 505px);
        min-height: 100vh;

        @include tablet {
          top: 70px;
          // position: relative;
        }
      }
    }
  }

</style>

<template lang="pug">

  .view-index
    navbar(:nav-links='navMenu')
    socialbar(:social-links='socialMenu')

    .content
      transition(name="slide" mode="out-in")
        router-view.view
      l-w-footer(v-if="user.id")

</template>

<script>

import Navbar from './components/lw-navbar/index.vue';
import Socialbar from './components/lw-social/index.vue';
import LWFooter from './components/lw-footer/index.vue';

export default {
  name: 'Layout',

  components: {
    Navbar,
    LWFooter,
    Socialbar
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  data() {
    return {
      navMenu: [
        {
          name: this.$t('LAYOUT.NAVBAR.HOME.TITLE'),
          link: '/'
        },
        {
          name: this.$t('LAYOUT.NAVBAR.TIMELINE.TITLE'),
          link: '/timeline'
        },
        {
          name: this.$t('LAYOUT.NAVBAR.EDITIONS.TITLE'),
          link: '/editions'
        },
        {
          name: this.$t('LAYOUT.NAVBAR.UNITS.TITLE'),
          link: '/units'
        }
      ],
      socialMenu: [
        {
          link: 'https://www.instagram.com/locaweb',
          img: '/assets/media/social/instagram.svg'
        },
        {
          link: 'https://twitter.com/locaweb',
          img: '/assets/media/social/twitter.svg'
        }
      ]
    };
  },

  watch: {
    $route() {
      document.title = this.$route.meta.title || 'Livro da Cultura';
    }
  }
};

</script>
