<style lang="scss" scoped>

  .footer-index {

    .container {
      margin-top: 100px;

      .footer__header {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        border-bottom: 2px solid rgb(44, 62, 80);

        @include tablet {
          flex-direction: column;
        }

        &--col {
          display: flex;
          align-items: center;

          @include tablet {
            justify-content: center;
            margin-bottom: 24px;
          }

          &:last-child {
            flex: 1;
            justify-content: flex-end;
            gap: 20px;

            @include tablet {
              justify-content: center;
              margin-bottom: 0;
            }

            @include cellphone {
              flex-direction: column;
            }
          }
        }

        &--title {
          text-transform: uppercase;
          font-size: 1.5rem;
        }

        &--paragraph {
          font-size: 1rem;
        }

        &--button {
          background-color: $color-secondary-dark;
          border: none;
          text-transform: uppercase;
          color: $text-color-secondary;
          font-weight: 700;
          font-size: 1rem;
          width: 80%;
          max-width: 240px;
          padding: 20px;
          border-radius: 8px;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
        }
      }

      .footer__body {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;

        @include tablet {
          flex-direction: column;
        }

        &--col {
          width: 50%;

          @include tablet {
            width: 100%;
          }

          .title {
            margin-bottom: 30px;
            font-weight: 700;
            font-size: 1.5rem;

            @include tablet {
              text-align: center;
            }
          }

          form {
            .form-group {
              display: flex;

              @include tablet {
                justify-content: center;
                margin-bottom: 24px;
              }

              .input {
                padding: 10px;
                border: none;
                border-bottom: 1px solid #979797;
                background: transparent;
                width: 380px;
                font-size: 1rem;
              }

              .button {
                background-color: $color-secondary-dark;
                border: none;
                width: 2.75rem;
                height: 2.75rem;
                border-radius: 8px;
                cursor: pointer;
                font-size: 1rem;
              }
            }
          }

          &:last-child {
            display: flex;

            @include tablet {
              text-align: center;
              gap: 20px;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }

      .footer__footer {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;

        @include cellphone {
          flex-direction: column;
          text-align: center;
        }

        .col {
          display: flex;
          gap: 20px;

          @include cellphone {
            margin: 12px 0;
            justify-content: center;
          }
        }
      }
    }
  }

</style>

<template lang="pug">

  .footer-index
    .container
      .footer__header
        .footer__header--col
          h1.footer__header--title Explorar edição {{ actualYear }}
        .footer__header--col
          p.footer__header--paragraph Você está preparado?
          router-link(:to="{ name: 'Edition', params: { id: actualYear } }").footer__header--button Iniciar

      .footer__body
        .footer__body--col
          h2.title Quer acompanhar nossas novidades?

          //- form(@submit.prevent="handleForm")
          //-   .form-group
          //-     input.input(placeholder="Digite ser e-mail")
          //-     button.button(type="submit" value="Enviar") >

        .footer__body--col

          l-w-list(v-for="(list, index) in footerLinks" :key="index" :list="list")

      .footer__footer
        .col
          span Termos e Condições
          span Política de privacidade
        .col
          img(src="@/assets/img/social/twitter.svg")
          img(src="@/assets/img/social/instagram.svg")

</template>

<script>

import LWList from './components/lw-list.vue';

export default {
  name: 'LWFooter',

  components: {
    LWList
  },

  data() {
    return {

      editions: [],
      links: {
        title: 'Links',
        items: [
          {
            label: 'Home',
            link: '/'
          },
          {
            label: 'Linha do tempo',
            link: '/timeline'
          },
          {
            label: 'Unidades',
            link: '/units'
          }
        ]
      },
      ajuda: {
        title: 'Ajuda',
        items: [
          {
            label: 'Contato',
            link: '/'
          }
        ]
      }
    };
  },

  mounted() {
    this.getEditions();
  },

  computed: {
    footerLinks() {
      return [
        {
          title: 'Edições',
          items: this.editions.map((edition) => ({
            label: edition.ano,
            link: `${process.env.VUE_APP_API}${edition.pdf[0].url}`
          }))
        },
        this.links
      ];
    },

    actualYear() {
      const date = new Date();

      return date.getFullYear();
    }
  },

  methods: {
    getEditions() {
      this.$api.get('/edicoes')
        .then((response) => {
          // time to sort inverse
          this.editions = response.data.sort((a, b) => a.ano - b.ano);
        });
    },

    handleForm() {
      console.log('boo');
    }
  }
};
</script>
