import Vue from 'vue';
import events from 'events-vue-allin';
import VTooltipPlugin from 'v-tooltip';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './services/i18n';
import api from './services/api';
import './widgets';

import 'v-tooltip/dist/v-tooltip.css';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;

Vue.prototype.$api = api;

Vue.use(events);
Vue.use(VTooltipPlugin);
Vue.use(VueSweetalert2);

if (localStorage.getItem('user')) {
  store.dispatch('setUser', {
    user: JSON.parse(localStorage.getItem('user')),
    jwt: localStorage.getItem('token')
  });
}

new Vue({
  router,
  store,
  events,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
