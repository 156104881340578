<style lang="scss" scoped>

  .layout-header {
    padding: 20px;

    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;

      @include tablet {
        flex-direction: column;
        height: auto;
      }

      img {
        position: absolute;
        left: 30px;

        @include tablet {
          position: static;
          margin-bottom: 10px;
        }
      }

      .menu {
        list-style: none;
        display: flex;
        justify-content: center;

        @include tablet {
          flex-wrap: wrap;
        }
        &__login {
          background: #f00842;
          border: 1px solid #f00842;
          font-weight: bold;
          padding: 5px 10px;
          border-radius: 5px;
        }

        &__link {
          cursor: pointer;
          padding: 0 25px;
          margin: 10px 0;
          font-size: 1rem;

          @include cellphone {
            padding: 0 12px;
          }

          a {
            text-decoration: none;
            color: $color-primary;

            &.router-link-exact-active {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

</style>

<template lang="pug">

  .layout-header
    .flex
      img(:src="logo" width="80")
      ul.menu
        li.menu__link(v-for="(menu, index) in navLinks")
          router-link(:to="menu.link", :key="index") {{ menu.name }}
        li.menu__link.menu__login
          a(v-if="user.username" @click="logout") {{user.username}} | Sair
          router-link(v-else to="/login") Acessar

      .the-invisible

</template>

<script>

import logo from '@/assets/logos/lw-logo.png';

export default {
  name: 'Navbar',

  props: {
    navLinks: { type: Array, required: true }
  },

  data() {
    return {
      logo
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  }
};

</script>
