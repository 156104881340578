import axios from 'axios';
import store from '../../store';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API
});

const mock = axios.create({
  baseURL: process.env.VUE_APP_MOCKUP
});

const setHeaders = () => {
  let headers = {};
  if (store.getters.jwt) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${store.getters.jwt}`
    };
  }
  instance.defaults.headers.common = headers;
};

const api = {
  get(endpoint) {
    setHeaders();
    return instance.get(endpoint);
  },

  post(endpoint, body) {
    setHeaders();
    return instance.post(endpoint, body);
  },

  patch(endpoint, body) {
    setHeaders();
    return instance.patch(endpoint, body);
  },

  delete(endpoint, body) {
    setHeaders();
    return instance.delete(endpoint, body);
  },

  mock(endpoint) {
    setHeaders();
    return mock.get(endpoint);
  }
};

export default api;
