const LandingPageRoute = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../../views/landing-page/home/index.vue'),
    meta: { title: 'LW - HOME' }
  },
  {
    path: '/timeline',
    name: 'Timeline',
    component: () => import('../../views/landing-page/timeline/index.vue'),
    meta: { title: 'LW - TIMELINE', requiresAuth: true }
  },
  {
    path: '/editions',
    name: 'Editions',
    component: () => import('../../views/landing-page/editions/index.vue'),
    meta: { title: 'LW - EDIÇÕES', requiresAuth: true }
  },
  {
    path: '/units',
    name: 'Units',
    component: () => import('../../views/landing-page/units/index.vue'),
    meta: { title: 'LW - UNIDADES', requiresAuth: true }
  }
];

export default LandingPageRoute;
