<style lang="scss" scoped>

.social {
  position: absolute;
  left: 0;
  top: 280px;
  font-weight: 100;
  transform: rotate(90deg);
  transform-origin: 10%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  font-family: 'Open Sans';
  font-size: 14px;

  @include tablet {
    top: 255px;
  }

  ul {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: flex-start;

    li {
      list-style: none;
      transform: rotate(-90deg);

      a {
        background: url('/img/social/instagram.svg') no-repeat;
        color: transparent;

        img {
          width: 20px;
        }
      }
    }
  }
}

</style>

<template lang="pug">

  .social
      ul.menu Acompanhe
        li.menu__link(v-for="(social, index) in socialLinks")
          a(:href='social.link' target='_blank')
            img(:src='social.img')
      .the-invisible
</template>

<script>

export default {
  name: 'Social',

  props: {
    socialLinks: { type: Array, required: true }
  }

};

</script>
