const EditionsRoute = [
  {
    path: '/editions/:id',
    name: 'Edition',
    component: () => import('../../views/edition/index.vue'),
    meta: { requiresAuth: true }
  }
];

export default EditionsRoute;
