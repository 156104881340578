export default {
  LAYOUT: {
    NAVBAR: {
      HOME: {
        TITLE: 'Home'
      },
      TIMELINE: {
        TITLE: 'Linha do tempo'
      },
      EDITIONS: {
        TITLE: 'Edições'
      },
      UNITS: {
        TITLE: 'Unidades'
      }
    }
  }
};
