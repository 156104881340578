<style lang="scss">

  .lwlist {
    width: 100%;

    &__title {
      color: $color-secondary;
      font-weight: 700;
      font-size: 1.25rem;
    }

    &__list {
      list-style: none;
      padding: 18px 0;
    }

    &__item {
      padding: 8px 0;
    }

    &__link {
      text-decoration: none;
      color: $color-primary;
      font-size: 1.18rem;
    }
  }

</style>

<template lang="pug">

  .lwlist
    h3.lwlist__title {{ list.title }}
    ul.lwlist__list
      li.lwlist__item(v-for="(item, index) in list.items" :key="index")
        a.lwlist__link(:href="item.link" target="_blank") {{ item.label }}

</template>

<script>

export default {
  name: 'LWList',

  props: {
    list: { type: Object, required: true }
  }

};

</script>
