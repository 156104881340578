import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    authenticated: false,
    token: ''
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
      state.authenticated = true;
      localStorage.setItem('user', JSON.stringify(user));
    },
    logout: (state) => {
      state.user = {};
      state.authenticated = false;
      state.token = '';
      localStorage.removeItem('user');
    },
    setToken: (state, token) => {
      state.token = token;
      localStorage.setItem('token', token);
    }
  },
  actions: {
    setUser: ({ commit }, data) => {
      commit('setUser', data.user);
      commit('setToken', data.jwt);
    },
    logout: ({ commit }) => {
      commit('logout');
    }
  },
  getters: {
    user: (state) => state.user,
    jwt: (state) => state.token
  },
  modules: {}
});
