<style lang="scss">

@import "/assets/scss/grid.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: 16px;

  @include tablet {
    font-size: 90%;
  }

  @include cellphone {
    font-size: 80%;
  }
}

#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-primary;
  background: $background-dark;
  min-height: 100vh;
}

</style>

<template lang="pug">

  #app
    layout

</template>

<script>

import Layout from '@/layout/index.vue';

export default {
  components: {
    Layout
  }
};

</script>
