<style lang="scss">

  .title-index {
    font-family: $primary-font;

    .content {
      color: $color-secondary;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 5px;

      @include tablet {
        font-size: 12px;
      }

      &::before {
        content: "";
        width: 55px;
        height: 1px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 2.18rem;
        background-color: $color-secondary;

        @include tablet {
          width: 30px;
        }
      }
    }

    .content-full {
      color: $color-secondary;
      text-transform: uppercase;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      &::after,
      &::before {
        content: '';
        width: 55px;
        height: 1px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 2.18rem;
        background-color: $color-secondary;
      }
    }
  }

</style>

<template lang="pug">

  .title-index
    span(:class="line")
      slot

</template>

<script>

export default {
  name: 'Title',

  props: {
    side: { type: String, default: 'left' }
  },

  computed: {
    line() {
      if (this.side === 'full') return 'content-full';
      return 'content';
    }
  }
};

</script>
